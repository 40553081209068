import { isType } from "@/utils.js";
// Form input fields validators

/**
 * Validates required input fields
 * @param {String} val Input field value
 * @param {String} fieldName Input field name to be validated
 * @returns {Function}
 */
export const required = (fieldName, errorMessage = null) => {
  return (val) => !!val.trim() || errorMessage || `${fieldName} is required.`;
};

/**
 * Validates input field maxlength
 * @param {String} fieldname Field name to be validated
 * @param {Number} length Maxlenght of the field to be validated
 * @returns {Function}
 */
export const maxLength = (length, fieldname, isRequired = true) => {
  return (val) => {
    let errMsg = isType(val, "string")
      ? `${fieldname} must be less than ${length} characters`
      : null;

    if (!isRequired && !val) {
      return true;
    }

    return (
      val?.length <= length ||
      (errMsg ?? `Selected ${fieldname} must be ${length}`)
    );
  };
};

/**
 * Validates numeric values of the input field
 * @param {String} fieldName Field name to be validated
 * @returns {Boolean | String}
 */
export const numeric = (fieldName) => {
  const pattern = /^(0|[1-9][0-9]*)$/;

  return (val) => {
    if (!val) {
      return true;
    }
    return pattern.test(val) || `${fieldName} should be a number`;
  };
};

/**
 * Validates the number input field for the maximum value
 * @param {Number} length
 * @param {String} fieldName
 */
export const maxLengthNumber = (length, fieldName) => {
  return (val) =>
    (isFinite(val) && val <= length) ||
    `${fieldName} must be lesser than ${length}`;
};

/**
 * Validates the input field value for alpha numeric values only
 * @param {String} fieldName Name of the fieldname to bevalidates
 * @returns {Function}
 */
export const alphaNumeric = (fieldName) => {
  const validator = /^[a-zA-Z0-9_ ]*$/;
  return (val) =>
    validator.test(val) || `${fieldName} should have alpha numeric values`;
};

/**
 * Validate email field
 * @param {String} val Input field value
 * @return {Boolean | String}
 */
export const validateEmail = (value) => {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(value) || "Please enter a valid email address.";
};

/**
 * Validates required input field for multiple values
 * @param {String} val Input field value
 * @param {String} fieldName Input field name to be validated
 * @returns {Function} Validator function
 */
export const requiredMultiple = (fieldName) => {
  return (val) => !!(val && val.length > 0) || `${fieldName} are required.`;
};

/**
 * Accept only alphabet and space
 * @param {String} value Input field value
 * @returns {String} Returns error message
 */
export const letterOnly = (value) => {
  const pattern = /^[A-Za-z ]+$/;
  return (
    pattern.test(value) || `Special characters and numbers are not allowed`
  );
};

/**
 * Validates number in input form elements
 * @param {String} fieldName Name of the input field to be updated
 * @param {Boolean} isZeroAllowed is zero value of input field valid or not
 * @returns {String | Boolean} Is input field value valid or not
 */
export const requiredNumber = (fieldName, isZeroAllowed = false) => {
  const isValid = (val) => val || (isZeroAllowed && val === 0);
  return (val) => !!isValid(val) || `${fieldName} are required.`;
};

/**
 * Avoid user to enter "e" or "E" char in number fields
 * @param {Object} event Dom event object
 */
export const onlyNumber = (event) => {
  if (!event) return;
  let keyCode = event.keyCode ? event.keyCode : event.which;
  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
    event.preventDefault();
  }
};
/**
 * Allow only numbers in input field
 */
export const onlyNumberKey = (evt) => {
  // Only ASCII character in that range allowed
  var ASCIICode = evt.which ? evt.which : evt.keyCode;
  if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
    evt.preventDefault();
  return true;
};

/**
 * Validate phone number format.
 */
export const validatePhone = () => {
  const pattern = /^\+?(91)?[6-9]\d{9}$|^\+?[1-9]\d{6,14}$/;
  return (val) => !val || pattern.test(val) || "Invalid phone number";
};

/**
 * Validate that input does not contain emojis.
 */
export const isEmoji = () => {
  const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/gu;
  return (val) => !val.trim() || !emojiRegex.test(val) || "Emojis are not allowed";
};
