<template>
  <div id="user-profile-form" class="w-100 py-5 ml-5">
    <v-row class="d-flex justify-center pb-4">
      <v-col cols="12" lg="12" md="12">
        <h1 class="subtitle-1 secondary--font font-weight-large">
          User Profile
        </h1>
      </v-col>
    </v-row>
    <v-form @submit.prevent="updateUserProfile" v-model="isFormValid">
      <v-row>
        <v-col sm="6" cols="12" md="6" lg="6">
          <v-text-field
            hide-details="auto"
            outlined
            label="First Name"
            class="required"
            v-model.trim="currentUser.firstName"
            :rules="[required('First Name'), isEmoji()]"
          />
        </v-col>
        <v-col sm="6" cols="12" md="6" lg="6">
          <v-text-field
            hide-details="auto"
            outlined
            label="Last Name"
            class="required"
            v-model.trim="currentUser.lastName"
            :rules="[required('Last Name'), isEmoji()]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6" cols="12" md="6" lg="6">
          <v-text-field
            disabled
            outlined
            label="Email"
            class="required user-email-address"
            hide-details="auto"
            v-model="currentUser.email"
            :rules="[required('Email')]"
          >
            <template #append>
              <tooltip
                #default="{ on, attrs }"
                type="success"
                contentClass="black--text cursor-pointer"
                name="This email address is verified"
              >
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                >
                  mdi-check-decagram
                </v-icon>
              </tooltip>
            </template>
          </v-text-field>
        </v-col>
        <v-col sm="6" cols="12" md="6" lg="6" class="cursor-not-allowed">
          <v-text-field
            hide-details="auto"
            outlined
            label="Company Name"
            v-model="currentUser.companyName"
            :rules="[isEmoji()]"
          />
        </v-col>
      </v-row>
      <v-row class="mb-2">
        <v-col sm="6" cols="12" md="6" lg="6">
          <v-text-field
            outlined
            hide-details="auto"
            label="Phone Number"
            class="user-phonenumber"
            v-model="currentUser.phoneNumber"
            :rules="[validatePhone()]"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="left">
          <v-btn
            type="submit"
            color="dark-black"
            :loading="isLoading"
            :disabled="!isFormValid"
            class="font-weight-bold white--text"
            v-track="'save-profile-changes-submit-btn'"
          >
            Save Changes
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <snackbar
      @close="closeSnackbar"
      :snackbarValue="!!successMessage"
      :snackBarType="'success'"
    >
      {{ successMessage }}
    </snackbar>
  </div>
</template>

<script>
import { pick, isEmpty, sanitizePayload } from "@/utils";
import Snackbar from "@/components/shared/Snackbar.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

import { updateUserProfile } from "@/services/users";
import { required, validatePhone, isEmoji} from "@/validators/form-validators";

import { KEYCLOAK_USER_DETAILS, USER_PROPS } from "@/constants/app";
import Tooltip from "../shared/Tooltip.vue";

/**
 * User profile form
 */
export default {
  name: "UserProfileForm",
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: {
    Snackbar,
    Tooltip
  },
  /**
  |--------------------------------------------------
  | Data properties
  |--------------------------------------------------
  */
  data() {
    return {
      currentUser: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        companyName: "",
        password: "Test@123",
      },
      isLoading: false,
      isDisabling: false,
      isFormValid: false,
      successMessage: "",
    };
  },
  /**
  |--------------------------------------------------
  | Watching properties
  |--------------------------------------------------
  */
  watch: {
    currentUserProfile: {
      handler(value) {
        if (!isEmpty(value)) {
          this.currentUser = pick(value, [...USER_PROPS, "email", "attributes"]);
        }
      },
      immediate: true,
    },
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    ...mapGetters({
      currentUserProfile: "auth/userProfile"
    }),
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    required,
    validatePhone,
    isEmoji,
    ...mapActions({
      setSnackbar: "ui/setSnackbar",
    }),
    ...mapMutations({
      updateUserDetails: "auth/SET_USER_PROFILE_DETAILS",
    }),
    /**
     * Closes the snackbar
     */
    closeSnackbar() {
      this.successMessage = "";
    },
    /**
     * Updated logged in user profile
     */
    async updateUserProfile() {
      try {
        this.isLoading = true;
        // Prepare the payload by merging user profile data
        const { currentUserProfile, currentUser } = this;
        const payload = {
          ...currentUserProfile,
          ...currentUser,
          attributes: {
            ...currentUserProfile.attributes,
            companyName: [currentUser.companyName],
            phoneNumber: [currentUser.phoneNumber],
          },
        };

        sanitizePayload(payload)
        
        // Call Keycloak API to update the user profile
        await updateUserProfile(payload);

        // Update user details in the application
        await Promise.allSettled([
          this.updateUserDetails(pick(currentUser, KEYCLOAK_USER_DETAILS)),
        ]);
        this.successMessage = `Profile was successfully updated.`;
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: error?.message,
          type: this.$appConfig.snackbar.snackbarTypes.error,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.verify-user-email__content,
.user-email-address,
.user-mfa-phonenumber {
  pointer-events: all !important;
}
</style>
